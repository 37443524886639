import {
  AfterViewInit,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Types } from '@contrail/sdk';
import { PropertyType, TypeProperty } from '@contrail/types';
import { ObjectUtil } from '@contrail/util/lib/object-util/object-util';
import { Store } from '@ngrx/store';
import _ from 'lodash';
import { SortDefinition, SortDirection } from '../components/sort/sort-definition';
import { VirtualScrollerComponent } from '@iharbeck/ngx-virtual-scroller';
import { BehaviorSubject, combineLatest, Subject, Subscription } from 'rxjs';
import { distinctUntilChanged, startWith, tap } from 'rxjs/operators';
import { RootStoreState } from 'src/app/root-store';
import { AssortmentsSelectors } from '../assortments/assortments-store';
import { SearchBarComponent } from '../components/search-bar/search-bar.component';
import { ItemData } from '../item-data/item-data';
import { FilterConditionType, FilterCriteria } from '@contrail/filters';
import { FilterDefinition, FilterPropertyDefinition } from '../types/filters/filter-definition';
import { AssortmentChooserDataSource } from './chooser-sources/assortment-chooser-data-source';
import { ChooserFilterConfig, ItemDataChooserDataSource } from './chooser-sources/item-data-chooser-data-source';
import { ItemLibraryChooserDataSource } from './chooser-sources/item-library-chooser-data-source';
import { SourceAssortmentChooserDataSource } from './chooser-sources/source-assortment-chooser-data-source';
import { ChooserSourceOption, ChooserSourceOptionTypes } from './source-option';
import { ItemService } from '@common/items/item.service';
import { ClipboardChooserDataSource } from './chooser-sources/clipboard-chooser-data-source';
import { ClipboardActions } from '@common/clipboard/clipboard-store';
import { ProjectItemService } from '@common/projects/project-item.service';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { AuthSelectors } from '@common/auth/auth-store';
import { FeatureFlagsSelectors } from '@common/feature-flags';
import { Feature } from '@common/feature-flags/feature-flag';

export interface DisableEntityCheck {
  isDisabled: boolean;
  reason?: string;
}

const DEFAULT_SORTS: SortDefinition[] = [
  { direction: SortDirection.ASCENDING, propertySlug: 'name', propertyType: PropertyType.String },
  { direction: SortDirection.ASCENDING, propertySlug: 'optionName', propertyType: PropertyType.String },
];

const DEFAULT_CLIPBOARD_SORTS: SortDefinition[] = [
  { direction: SortDirection.DESCENDING, propertySlug: 'clipboardItemUpdatedOn', propertyType: PropertyType.Date },
  { direction: SortDirection.ASCENDING, propertySlug: 'name', propertyType: PropertyType.String },
  { direction: SortDirection.ASCENDING, propertySlug: 'optionName', propertyType: PropertyType.String },
];

const ITEM_TYPE_PROPERTY = {
  label: 'Item Type',
  slug: 'type',
  propertyType: PropertyType.TypeReference,
  referencedTypePath: 'item',
  referencedTypeRootSlug: 'item',
};

@Component({
  selector: 'app-item-data-chooser',
  templateUrl: './item-data-chooser.component.html',
  styleUrls: ['./item-data-chooser.component.scss'],
})
export class ItemDataChooserComponent implements AfterViewInit, OnInit, OnDestroy, OnChanges {
  public title = "Didn't find anything...";
  public icon = 'item-placeholder';
  public footer = 'Try different search criteria';
  public actionLabel = '';

  constructor(
    private store: Store<RootStoreState.State>,
    private snackBar: MatSnackBar,
    private itemService: ItemService,
    private projectItemService: ProjectItemService,
  ) {}

  @Input() chooserTitle: string;
  @Input() resultsHeight = '100%';
  @Input() draggable = true;
  @Input() arrowKeyNavigationEnabled = true;
  @Input() currentAssortmentItemData: Array<ItemData> = [];
  @Input() existingItemIds: Set<string>;
  @Input() targetSourceType: ChooserSourceOptionTypes;
  @Input() showSearchBox = true;
  @Input() showAllControl = true;
  @Input() showCount = true;
  @Input() showHeader = true;
  @Input() showFilter = true;
  @Input() hideSource = false;
  @Input() allowAddMultipleEntities = false;
  @Input() baseCriteria: any = { roles: 'option', isPrimary: true };
  @Input() QUICK_SEARCH_OPTIONS =
    'name, item.name, item.atts.styleNumber, atts.colorCode, item.atts.collection, item.atts.division';
  @Input() itemChooserFilterTemplateName = 'item_chooser_filter';
  @Input() itemCardViewTemplateName = 'item_card_meta';
  @Input() allowAddEntity = false;
  @Input() allowAddDuplicate = true;
  @Input() allowItemLevelSelection = false;
  @Input() allowShowDetails = true;
  @Input() isEntityDisabled: ((entity: ItemData) => DisableEntityCheck) | undefined;
  @Input() projectId;
  @Input() enableInfiniteScroll = false;
  @Input() shouldDefaultToSource = false;
  @Input() shouldOnlyPersistDocumentSources = false;
  @Input() shouldHydrateAddedItemsRelativeToSource = false;
  @Input() ignorePersistedBaseCriteria = false;
  @Input() minimumItemCountOnLoad: number;

  @ViewChild(SearchBarComponent) searchBar: SearchBarComponent;
  @ViewChild(VirtualScrollerComponent) virtualScroller: VirtualScrollerComponent;

  @Output() entityClicked: EventEmitter<any> = new EventEmitter();
  @Output() close = new EventEmitter();
  @Output() addEntity = new EventEmitter();
  @Output() addEntities = new EventEmitter();
  public level: 'option' | 'family' = 'option';
  public criteria: any;
  public hasSourceAssortment = false;
  public chooserSourceOption$: BehaviorSubject<ChooserSourceOption> = new BehaviorSubject(null);
  public isFilterActive = false;
  private dataSubscription: Subscription;
  private loadingSubscription: Subscription;
  private moreLoadingSubscription: Subscription;
  public data: Array<any>;
  private entityIdToDisableCheckMap = new Map<string, DisableEntityCheck>();
  public filteredResults$ = new Subject<Array<ItemData>>();
  public loading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public filterDefinition: FilterDefinition;
  private filterDefinitionSubject: BehaviorSubject<FilterDefinition> = new BehaviorSubject(null);
  private sortConfigSubject: BehaviorSubject<SortDefinition[]> = new BehaviorSubject(null);
  public dateFilter: FilterDefinition;
  private dateFilterSubject: BehaviorSubject<FilterDefinition> = new BehaviorSubject(null);
  public selectedDateFilterOption: string;
  public count = -1;
  public showAllSubject = new BehaviorSubject(true);
  public existingItemIdsSubject = new BehaviorSubject(new Set());
  public showSelectSource = false;
  public sourceAssortment: any;
  private sourceAssortmentSubscription: Subscription;
  public dataSource: ItemDataChooserDataSource;
  public dataSourceType: string;
  private chooserSourceSubscription: Subscription;
  private filterConfigSubject: BehaviorSubject<ChooserFilterConfig> = new BehaviorSubject(null);
  public selectedItems: any[] = [];
  public selectAll = false;
  public numberOfEligibleItems = 0;
  public propertyTypeDefaultFilterConditions: any = {};
  public sortProperties: Array<SortDefinition>;
  public currentSorts: Array<SortDefinition> = [];
  public showAll = true;
  public dateFilterAttribute = {
    label: 'Recently added',
    attribute: 'createdOn',
  };
  public areMoreResultsLoading = false;
  public hasLoaded = false;
  private isLoading = false;
  public isClipboard = false;
  public maximumItemCount = 2000;
  private searchBarSubscription: Subscription;
  public activeEntityId: string;
  public areMaterialsFeaturesEnabled: boolean = false;

  ngOnInit() {
    this.store.select(FeatureFlagsSelectors.featureFlags).subscribe((featureFlags) => {
      this.areMaterialsFeaturesEnabled = featureFlags.some((flag) => flag.featureName === Feature.MATERIAL_MVP);
    });

    this.criteria = ObjectUtil.cloneDeep(this.baseCriteria);
    if (this.criteria?.roles === 'family') {
      this.level = this.criteria?.roles;
    }

    const savedChooserCriteria = this.itemService.itemChooserCriteria$?.value;
    if (savedChooserCriteria?.baseCriteria && !this.ignorePersistedBaseCriteria) {
      this.criteria = savedChooserCriteria.baseCriteria;
      this.level = this.criteria.roles;
    }

    this.assignFilterCriteria();
    this.store.select(AuthSelectors.currentOrg).subscribe((org) => {
      if (org.orgConfig?.propertyTypeDefaultFilterConditions) {
        this.propertyTypeDefaultFilterConditions = org.orgConfig.propertyTypeDefaultFilterConditions;
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.targetSourceType) {
      const isFirstChange = changes.targetSourceType.firstChange;

      if (changes.targetSourceType.currentValue === ChooserSourceOptionTypes.CLIPBOARD) {
        this.handleSourceChange({
          sourceType: ChooserSourceOptionTypes.CLIPBOARD,
          name: 'Clipboard',
          icon: 'assets/images/clipboard.svg',
          class: 'opacity-50',
        });
      } else if (changes.targetSourceType.currentValue === ChooserSourceOptionTypes.ITEM_LIBRARY) {
        this.handleSourceChange({
          sourceType: ChooserSourceOptionTypes.ITEM_LIBRARY,
          name: 'Item Library',
          icon: 'assets/images/item.svg',
        });
      } else if (isFirstChange && this.shouldDefaultToSource) {
        const userSavedChooserSource = this.itemService.itemChooserCriteria$?.value?.chooserSource;
        if (userSavedChooserSource && !this.chooserSourceOption$.getValue()) {
          this.chooserSourceOption$.next(userSavedChooserSource);
          this.toggleSourceSourceSelector(false);
        }
      } else {
        const previousSource = this.chooserSourceOption$.getValue();
        const userSavedChooserSource = this.itemService.itemChooserCriteria$?.value?.chooserSource;
        const defaultSource = this.shouldDefaultToSource ? this.getDefaultSource() : null;

        const chooserSource = userSavedChooserSource || defaultSource;
        if (!chooserSource) {
          this.toggleSourceSourceSelector(true);
        } else if (!previousSource || chooserSource.sourceType !== previousSource.sourceType) {
          this.chooserSourceOption$.next(chooserSource);
          this.toggleSourceSourceSelector(false);
        }
      }
    }

    this.existingItemIdsSubject.next(this.existingItemIds);
    if (changes.baseCriteria) {
      if (
        ObjectUtil.compareDeep(changes.baseCriteria.currentValue, changes.baseCriteria.previousValue, '').length > 0
      ) {
        this.criteria = ObjectUtil.cloneDeep(changes.baseCriteria.currentValue);
        this.setLevelOnCriteria();
        this.initFilterObservable();
      }
    }
  }

  ngOnDestroy() {
    this.sourceAssortmentSubscription?.unsubscribe();
    this.chooserSourceSubscription?.unsubscribe();
    this.dataSubscription?.unsubscribe();
    this.loadingSubscription?.unsubscribe();
    this.moreLoadingSubscription?.unsubscribe();
    this.searchBarSubscription?.unsubscribe();
  }

  ngAfterViewInit(): void {
    const chooserCriteria = this.itemService.itemChooserCriteria$?.value;
    if (chooserCriteria?.sortCriteria) {
      this.currentSorts = chooserCriteria.sortCriteria;
    }

    this.initResultsObservable();
    this.hasLoaded = false;
    if (this.arrowKeyNavigationEnabled && this.searchBar) {
      this.searchBar?.focus();
    }
  }

  @HostListener('document:keydown', ['$event'])
  keyEvent(keyEvent: KeyboardEvent) {
    if (!this.arrowKeyNavigationEnabled) {
      return;
    }
    if (keyEvent.code === 'Escape') {
      this.close.emit();
    }
  }

  @HostListener('mousewheel', ['$event'])
  onMouseWheel(event) {
    event.stopPropagation();
  }

  assignFilterCriteria() {
    const chooserCriteria = this.itemService.itemChooserCriteria$?.value;
    if (chooserCriteria?.filterCriteria) {
      this.filterDefinition = { filterPropertyDefinitions: [], filterCriteria: chooserCriteria?.filterCriteria };
      this.filterDefinitionSubject.next(this.filterDefinition);
    }

    const shouldNotShowAll = Boolean(!this.allowAddEntity && !this.allowAddDuplicate);
    if (shouldNotShowAll) {
      this.showAll = false;
      this.showAllSubject.next(this.showAll);
    } else if (typeof chooserCriteria?.filterByShowAll === 'boolean') {
      this.showAll = chooserCriteria.filterByShowAll;
      this.showAllSubject.next(this.showAll);
    }

    if (chooserCriteria?.dateFilter) {
      this.dateFilter = chooserCriteria.dateFilter.criteria;
      this.dateFilterSubject.next(this.dateFilter);

      this.selectedDateFilterOption = chooserCriteria.dateFilter.option;
    }
  }

  async initFilterDefinition(sourceType: string) {
    this.dataSourceType = sourceType;
    let filterProperties: Array<TypeProperty>;
    if (
      sourceType === ChooserSourceOptionTypes.SOURCE_ASSORTMENT ||
      sourceType === ChooserSourceOptionTypes.ASSORTMENT
    ) {
      const itemType = await new Types().getType({ root: 'item', path: 'item', relations: ['typeProperties'] });
      const assortmentItemType = await new Types().getType({
        root: 'assortment-item',
        path: 'assortment-item',
        relations: ['typeProperties'],
      });
      const projectItemType = await new Types().getType({
        root: 'project-item',
        path: 'project-item',
        relations: ['typeProperties'],
      });
      filterProperties = [
        ...itemType?.typeProperties,
        ...assortmentItemType?.typeProperties,
        ...projectItemType?.typeProperties,
      ];
    } else {
      const itemType = await new Types().getType({ root: 'item', path: 'item', relations: ['typeProperties'] });
      filterProperties = [...itemType?.typeProperties];
    }

    filterProperties.push(ITEM_TYPE_PROPERTY);
    filterProperties = filterProperties.sort((p1, p2) => (p1.label < p2.label ? -1 : 1));
    this.filterDefinition = {
      filterPropertyDefinitions: filterProperties as Array<FilterPropertyDefinition>,
      filterCriteria: this.filterDefinition?.filterCriteria ?? { propertyCriteria: [] },
    };
    this.sortProperties = filterProperties.map((property) => {
      return {
        propertySlug: property.slug,
        propertyLabel: property.label,
        propertyType: property.propertyType,
        direction: SortDirection.ASCENDING,
      };
    });
    this.isFilterActive = Boolean(this.filterDefinition?.filterCriteria?.propertyCriteria?.length);
  }
  initResultsObservable(): void {
    /**
     * Respond to changes in chooser source.  Create a new data source based on the selection.
     * Each data source is responsible for fetching & filtering data as appropriate based
     * on changes in the choosers 'filter config', which includes the search term and filter definition.
     */
    this.chooserSourceSubscription = this.chooserSourceOption$.subscribe((chooserSourceOption) => {
      console.log('ChooserComponent: chooserSourceOption change: ', chooserSourceOption);
      this.isClipboard = chooserSourceOption?.sourceType === ChooserSourceOptionTypes.CLIPBOARD;

      if (!chooserSourceOption) {
        return;
      }

      if (this.isClipboard) {
        this.configureChooserForClipboard();
      }

      this.cleanUpPriorDataSource();
      this.sortData();

      if (chooserSourceOption.sourceType === ChooserSourceOptionTypes.SOURCE_ASSORTMENT) {
        console.log('ChooserComponent: creating SourceAssortmentChooserDataSource');
        this.dataSource = new SourceAssortmentChooserDataSource(
          this.store,
          this.filterConfigSubject,
          this.sortConfigSubject,
          this.existingItemIdsSubject,
          this.showAllSubject,
          this.dateFilterSubject,
          this.allowItemLevelSelection,
        );
      } else if (chooserSourceOption.sourceType === ChooserSourceOptionTypes.ITEM_LIBRARY) {
        console.log('ChooserComponent: creating ItemLibraryChooserDataSource');
        this.dataSource = new ItemLibraryChooserDataSource(
          this.store,
          this.filterConfigSubject,
          this.sortConfigSubject,
          this.existingItemIdsSubject,
          this.showAllSubject,
          this.projectId,
          this.enableInfiniteScroll,
        );
      } else if (chooserSourceOption.sourceType === ChooserSourceOptionTypes.ASSORTMENT) {
        console.log('ChooserComponent: creating AssortmentChooserDataSource');
        this.dataSource = new AssortmentChooserDataSource(
          this.store,
          this.filterConfigSubject,
          this.sortConfigSubject,
          this.existingItemIdsSubject,
          this.showAllSubject,
          this.dateFilterSubject,
          chooserSourceOption.id,
          this.allowItemLevelSelection,
        );
      } else if (chooserSourceOption.sourceType === ChooserSourceOptionTypes.CLIPBOARD) {
        console.log('ChooserComponent: creating ClipboardChooserDataSource');
        this.dataSource = new ClipboardChooserDataSource(
          this.store,
          this.filterConfigSubject,
          this.sortConfigSubject,
          this.existingItemIdsSubject,
          this.showAllSubject,
        );
      }

      if (!this.dataSource) {
        return;
      }

      this.initFilterDefinition(chooserSourceOption.sourceType);
      this.sortData();

      this.dataSubscription = this.dataSource.results$.subscribe((results) => {
        this.data = results;
        this.setDisabledItemsAsDisabled();
        this.numberOfEligibleItems = this.data.filter((item) => this.isItemSelectable(item)).length;

        const isFirstPageOfResults = Boolean(
          !this.enableInfiniteScroll ||
            !(this.dataSource instanceof ItemLibraryChooserDataSource) ||
            this.dataSource.isFirstPageOfItems,
        );

        if (isFirstPageOfResults) {
          this.clearSelectedItems();
        }

        if (this.isLoading) {
          return;
        }

        const areThereMoreResultsToLoad = Boolean(
          this.enableInfiniteScroll &&
            this.dataSource instanceof ItemLibraryChooserDataSource &&
            (results.length === 0 || (this.minimumItemCountOnLoad && this.data.length < this.minimumItemCountOnLoad)) &&
            this.dataSource.nextPageKey,
        );

        if (areThereMoreResultsToLoad) {
          (this.dataSource as ItemLibraryChooserDataSource).getMorePaginatedResults();
        } else {
          this.hasLoaded = true;
        }
      });

      this.loadingSubscription = this.dataSource.loading$.subscribe((isLoading) => {
        if (isLoading) {
          this.hasLoaded = false;
        }

        this.isLoading = isLoading;
      });

      if (this.dataSource instanceof ItemLibraryChooserDataSource) {
        this.moreLoadingSubscription = this.dataSource.moreResultsLoading$.subscribe((moreResultsLoading) => {
          this.areMoreResultsLoading = moreResultsLoading;
        });
      }
    });

    /**
     * Respond to change in contextual source assortment
     */
    this.sourceAssortmentSubscription = this.store
      .select(AssortmentsSelectors.sourceAssortment)
      .pipe(
        tap((source) => {
          console.log('ChooserComponent: sourceAssortment change');
          if (source) {
            this.sourceAssortment = source;
          }

          if (this.shouldDefaultToSource) {
            if (this.dataSource) {
              return;
            }

            const defaultSource = this.getDefaultSource();
            this.chooserSourceOption$.next(defaultSource);

            this.initFilterObservable();
            this.saveChooserCriteria();
          }
        }),
      )
      .subscribe();
  }

  initFilterObservable() {
    this.searchBarSubscription?.unsubscribe();

    if (this.searchBar) {
      this.searchBarSubscription = combineLatest([
        this.searchBar.valueChange.pipe(startWith(''), distinctUntilChanged()),
        this.filterDefinitionSubject.asObservable(),
      ])
        .pipe(
          tap(async ([searchTerm, filterDefinition]) => {
            if (!this.criteria.roles && this.level) {
              this.criteria.roles = this.level;
            }

            const filterConfig = {
              searchTerm,
              filterDefinition,
              baseCriteria: this.criteria,
            };

            this.clearSelectedItems();
            this.filterConfigSubject.next(filterConfig);
          }),
        )
        .subscribe();
    }
  }

  handleScrollEnd(event) {
    const isScrollAtEndOfContent = this.data?.length > 0 && event.endIndex === this.data.length - 1;
    if (
      this.enableInfiniteScroll &&
      isScrollAtEndOfContent &&
      this.dataSource instanceof ItemLibraryChooserDataSource &&
      this.dataSource.nextPageKey
    ) {
      this.dataSource.getMorePaginatedResults();
    }
  }

  startDrag(event) {
    const id = event.target.id;
    const itemData: ItemData = this.data.filter((e) => e.id === id).shift();
    const validItems = this.getValidItems([itemData]);
    if (validItems.length === 1) {
      this.buildHydratedItems(validItems).then((hydratedItems) => {
        const hydratedItemData = hydratedItems[0];
        event.dataTransfer.setData('entity', JSON.stringify(hydratedItemData));
        event.dataTransfer.setData('itemDataId', hydratedItemData.id);
        event.dataTransfer.setData('dataObject', JSON.stringify(hydratedItemData));
      });
    }
  }

  getImage(obj) {
    return obj.thumbnail;
  }
  handleClick(object) {
    if (this.isItemDisabled(object)) {
      this.showItemDisabledErrorMessage(object);
      return;
    }

    this.entityClicked.emit(object);
  }

  async addItemData(itemData: ItemData) {
    if (this.isItemDisabled(itemData)) {
      this.showItemDisabledErrorMessage(itemData);
      return;
    }

    this.addItems([itemData]);
  }

  toggleSelection(selection: any) {
    if (!selection.selected) {
      this.selectAll = false;
      this.selectedItems.splice(
        this.selectedItems.findIndex((selectedItem) => selectedItem.id === selection.itemData.id),
        1,
      );
    } else {
      this.selectedItems.push(ObjectUtil.cloneDeep(selection.itemData));
    }
  }

  setFilterCriteria(filterCriteria: FilterCriteria) {
    if (filterCriteria) {
      this.filterDefinition = {
        ...this.filterDefinition,
        filterCriteria: { ...filterCriteria },
      };

      this.filterDefinitionSubject.next(this.filterDefinition);
      this.saveChooserCriteria();
    }
    this.isFilterActive = Boolean(this.filterDefinition?.filterCriteria?.propertyCriteria?.length);
  }

  clearFilters() {
    this.filterDefinition = {
      ...this.filterDefinition,
      filterCriteria: { ...this.filterDefinition.filterCriteria, propertyCriteria: [] },
    };

    this.filterDefinitionSubject.next(this.filterDefinition);
    this.searchBar.clear();
  }

  isItemSelectable(itemData: ItemData) {
    if (this.isItemDisabled(itemData)) {
      return false;
    }

    return this.allowAddDuplicate || this.isClipboard || !this.isExistingItemOnSource(itemData);
  }

  isExistingItemOnSource(itemData: ItemData) {
    if (this.existingItemIds) {
      return this.existingItemIds.has(itemData.id);
    }

    return false;
  }

  showItemDisabledErrorMessage(itemData: ItemData) {
    const disableItemCheck = this.entityIdToDisableCheckMap.get(itemData.id);
    if (disableItemCheck.isDisabled && disableItemCheck.reason) {
      this.snackBar.open(disableItemCheck.reason, '', { duration: 5000 });
    }
  }

  isItemDisplayDisabled(itemData: ItemData) {
    if (this.allowAddMultipleEntities || this.allowAddEntity) {
      return false;
    }

    return this.isItemDisabled(itemData);
  }

  isItemDisabled(itemData: ItemData) {
    const disableItemCheck = this.entityIdToDisableCheckMap.get(itemData.id);
    return disableItemCheck.isDisabled;
  }

  setDisabledItemsAsDisabled() {
    this.data.forEach((item) => {
      const disableItemCheck = this.isEntityDisabled ? this.isEntityDisabled(item) : { isDisabled: false };
      this.entityIdToDisableCheckMap.set(item.id, disableItemCheck);
    });
  }

  toggleShowAll(event) {
    this.showAll = !event.checked;
    this.showAllSubject.next(!event.checked);
    this.saveChooserCriteria();
  }

  trackByFn(index, item: ItemData) {
    return item.id;
  }

  toggleSourceSourceSelector(val) {
    if (!this.chooserSourceOption$.value) {
      this.chooserSourceOption$.next({
        sourceType: ChooserSourceOptionTypes.ITEM_LIBRARY,
        name: 'Item Library',
        icon: 'assets/images/item.svg',
      });
    }
    this.searchBarSubscription?.unsubscribe();
    this.showSelectSource = val;
    setTimeout(() => {
      this.initFilterObservable();
    }, 1);
  }

  handleSourceChange(source) {
    this.cleanUpPriorDataSource();
    this.chooserSourceOption$.next(source);
    this.clearSelectedItems();
    this.toggleSourceSourceSelector(false);

    if (this.targetSourceType !== ChooserSourceOptionTypes.CLIPBOARD) {
      this.saveChooserCriteria();
    }
  }

  cleanUpPriorDataSource() {
    if (this.dataSource) {
      this.dataSource?.cleanUp();
      delete this.dataSource;
    }

    this.hasLoaded = false;
    this.dataSubscription?.unsubscribe();
    this.loadingSubscription?.unsubscribe();
    this.moreLoadingSubscription?.unsubscribe();
  }

  async handleAddSelectedItems() {
    this.addItems(this.selectedItems);
  }

  async handleAddAllItems() {
    this.addItems(this.data);
  }

  async handleDeleteClipboardItems() {
    const selectedClipboardItemIds = this.selectedItems.map((item) => item.clipboardItemId);
    this.store.dispatch(ClipboardActions.removeItemsFromClipboard({ ids: selectedClipboardItemIds }));
    this.clearSelectedItems();
  }

  async handleClearClipboard() {
    const allClipboardItemIds = this.data?.map((item) => item.clipboardItemId);
    this.store.dispatch(ClipboardActions.removeItemsFromClipboard({ ids: allClipboardItemIds }));
  }

  get isSelectAllChecked(): boolean {
    return (
      this.numberOfEligibleItems > 0 &&
      this.selectedItems.length > 0 &&
      (this.selectedItems.length === this.maximumItemCount || this.selectedItems.length === this.numberOfEligibleItems)
    );
  }

  private clearSelectedItems() {
    this.selectedItems = [];
    this.selectAll = false;
  }

  handleToggleSelectAll(event: MatCheckboxChange) {
    this.selectAll = event.checked;
    if (this.selectAll && this.allowAddMultipleEntities) {
      this.selectedItems = [];
      for (const item of this.data) {
        if (this.selectedItems.length >= this.maximumItemCount) {
          break;
        }

        const isItemSelectable = this.isItemSelectable(item);
        if (isItemSelectable) {
          this.selectedItems.push(ObjectUtil.cloneDeep(item));
        }
      }
    } else {
      this.selectedItems = [];
    }
  }

  getSelectedItemIndex(dataObj) {
    if (this.selectedItems.length === 0) {
      return 0;
    }
    return this.selectedItems?.findIndex((selectedItem) => selectedItem.id === dataObj.id) + 1;
  }

  performSort(event) {
    this.currentSorts = event.sorts;
    this.sortData();
    this.saveChooserCriteria();
  }

  sortData() {
    const defaultSort = this.isClipboard ? DEFAULT_CLIPBOARD_SORTS : DEFAULT_SORTS;
    const sorts = this.currentSorts.length > 0 ? this.currentSorts : defaultSort;
    this.sortConfigSubject.next(sorts);
  }

  dateFilterChanged(dateFilter) {
    this.dateFilter = dateFilter;
    this.dateFilterSubject.next(dateFilter);
    this.saveChooserCriteria();
  }

  dateFilterOptionChanged(dateFilterOption) {
    this.selectedDateFilterOption = dateFilterOption;
  }

  toggleFamilyOnly(event) {
    this.level = event.value;
    this.setLevelOnCriteria();

    const filterConfig = this.filterConfigSubject.getValue();
    this.filterConfigSubject.next({ ...filterConfig, criteria: this.criteria });
    this.saveChooserCriteria();
  }

  setLevelOnCriteria() {
    this.criteria.roles = this.level;
    if (this.level === 'family') {
      delete this.criteria.isPrimary;
    } else {
      this.criteria.isPrimary = true;
    }
  }

  private saveChooserCriteria() {
    if (!this.criteria) {
      return;
    }
    const chooserCriteriaOptions = {
      baseCriteria: { roles: this.criteria.roles, isPrimary: this.criteria.isPrimary },
      filterByShowAll: this.showAll,
      dateFilter: {
        criteria: this.dateFilter,
        option: this.selectedDateFilterOption,
      },
      filterCriteria: this.filterDefinition?.filterCriteria,
      sortCriteria: this.currentSorts,
      chooserSource: this.chooserSourceOption$.value,
    };

    const isSourceSavable =
      !this.shouldOnlyPersistDocumentSources ||
      (this.shouldOnlyPersistDocumentSources &&
        [
          ChooserSourceOptionTypes.ASSORTMENT,
          ChooserSourceOptionTypes.SOURCE_ASSORTMENT,
          ChooserSourceOptionTypes.DOCUMENT,
          ChooserSourceOptionTypes.RECENT_DOCUMENT,
        ].includes(chooserCriteriaOptions.chooserSource?.sourceType));

    if (this.targetSourceType === ChooserSourceOptionTypes.CLIPBOARD || !isSourceSavable) {
      delete chooserCriteriaOptions.chooserSource;
    }

    this.itemService.saveItemChooserCriteria(chooserCriteriaOptions);
  }

  private configureChooserForClipboard() {
    if (!this.isClipboard) {
      return;
    }

    this.level = 'option';
    this.setLevelOnCriteria();
  }

  public refreshData() {
    if (!this.isClipboard || !(this.dataSource instanceof ClipboardChooserDataSource) || this.isLoading) {
      return;
    }

    this.dataSource.loadClipboard();
  }

  private getDefaultSource() {
    if (this.sourceAssortment) {
      return {
        sourceType: ChooserSourceOptionTypes.SOURCE_ASSORTMENT,
        name: this.sourceAssortment?.name,
        workspaceId: this.sourceAssortment?.workspaceId,
        entityReference: 'assortment:' + this.sourceAssortment?.id,
        icon: 'assets/images/linked_icon.svg',
      };
    }

    return {
      sourceType: ChooserSourceOptionTypes.ITEM_LIBRARY,
      name: 'Item Library',
      icon: 'assets/images/item.svg',
    };
  }

  get chooserTitleText() {
    return this.chooserTitle ?? 'Item Chooser';
  }

  private async addItems(items: ItemData[]) {
    const validItems = this.getValidItems(items);

    if (validItems.length > this.maximumItemCount) {
      this.snackBar.open(
        `You can only add up to ${this.maximumItemCount} items at a time. Please add a filter and retry.`,
        '',
        { duration: 5000 },
      );
    } else {
      const hydratedItems = await this.buildHydratedItems(validItems);
      if (hydratedItems.length === 1) {
        this.addEntity.emit(hydratedItems[0]);
      } else if (hydratedItems.length > 1) {
        this.addEntities.emit(hydratedItems);
      }

      this.clearSelectedItems();
    }
  }

  private getValidItems(items: ItemData[]): ItemData[] {
    const canInvalidItemsBeSelected = Boolean(this.isClipboard && !this.allowAddDuplicate);
    const validItems = canInvalidItemsBeSelected ? items.filter((item) => !this.isExistingItemOnSource(item)) : items;
    return validItems;
  }

  private async buildHydratedItems(items: ItemData[]) {
    if (this.shouldHydrateAddedItemsRelativeToSource) {
      return await this.buildItemsRelativeToSource(items);
    } else if (this.projectId) {
      return await this.buildItemsWithHydratedProjectItemData(items);
    } else {
      return items;
    }
  }

  private async buildItemsWithHydratedProjectItemData(items: ItemData[]) {
    const projectItemIdsToFetch = items
      .filter((itemData) => {
        const hasAssociatedProject = Boolean(itemData.projectItem?.projectId);
        const isItemFamilyProjectItemMissing = Boolean(
          itemData.item?.itemFamilyId !== itemData.item?.id && !itemData.item?.itemFamily?.projectItem,
        );

        return hasAssociatedProject && isItemFamilyProjectItemMissing;
      })
      .map((itemData) => `${itemData.projectItem.projectId}:${itemData.item.itemFamilyId}`);

    const projectItems = await this.projectItemService.getByIds(projectItemIdsToFetch);

    const hydratedItems = items.map((itemData) => {
      if (!itemData.item.itemFamilyId) {
        return itemData;
      }

      const projectItem = projectItems.find((projectItem) => projectItem.itemId === itemData.item.itemFamilyId);
      if (projectItem) {
        return {
          ...itemData,
          item: {
            ...itemData.item,
            itemFamily: {
              ...itemData.item.itemFamily,
              projectItem: projectItem,
            },
          },
        };
      }

      return itemData;
    });

    return hydratedItems;
  }

  private async buildItemsRelativeToSource(itemsToHydrate: ItemData[]) {
    const itemsToAdd = ObjectUtil.cloneDeep(itemsToHydrate);
    const areAssortmentItems = itemsToAdd[0]?.assortmentItem;
    if (areAssortmentItems) {
      return itemsToAdd;
    }

    const itemDataRelativeToSource = await this.setItemDataRelativeToSource(itemsToAdd);
    if (itemDataRelativeToSource?.length > 0) {
      const items = [];
      itemsToAdd.forEach((item) => {
        const itemRelativeToSource = itemDataRelativeToSource.find((i) => i.id === item.id);
        if (itemRelativeToSource) {
          items.push(itemRelativeToSource);
        } else {
          items.push(item);
        }
      });
      return items;
    } else {
      return itemsToAdd;
    }
  }

  /**
   * Set assortment item and project item data that corresponds to the current
   * source assortment.
   * For example, if an item is added from items library but it is in the source assortment
   * it should have assortment item and project item data from the source.
   * Similarly, if an item is added from different assortment but it also exists in the current
   * source assortment, it should have assortment item and project item data from the source.
   * @param itemData
   * @returns
   */
  private async setItemDataRelativeToSource(itemData: ItemData[]): Promise<ItemData[]> {
    return await this.itemService.setItemDataRelativeToSource(itemData);
  }

  public get isItemTypeNavigable(): boolean {
    return (
      this.areMaterialsFeaturesEnabled && this.dataSource instanceof ItemLibraryChooserDataSource && this.showFilter
    );
  }

  public applyItemTypeFilter(itemTypeId: string) {
    const updatedFilterCriteria: FilterCriteria = ObjectUtil.cloneDeep(this.filterDefinition.filterCriteria || {});
    updatedFilterCriteria.propertyCriteria = updatedFilterCriteria.propertyCriteria || [];
    _.remove(updatedFilterCriteria.propertyCriteria, (criteria) => criteria?.filterPropertyDefinition?.slug === 'type');

    if (itemTypeId) {
      updatedFilterCriteria.propertyCriteria.push({
        criteriaValue: itemTypeId,
        filterConditionType: FilterConditionType.EQUALS,
        filterPropertyDefinition: ITEM_TYPE_PROPERTY,
      });
    }

    this.setFilterCriteria(updatedFilterCriteria);
  }
}
