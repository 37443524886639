import { Entities, SortOrderOptions, Types } from '@contrail/sdk';
import { DataGroupGenerator } from '@contrail/data-grouping';
import {
  ComponentGridTemplate,
  ComponentPropertyTemplate,
  DocumentGenerationOptions,
  DocumentGenerator,
  DocumentPropertyDefinition,
  DocumentTemplate,
  Orientation,
  PanelPropertyTemplate,
  ShowcaseFrameGenerator,
} from '@contrail/document-generation';
import { SortDefinition, SortDirection } from '@components/sort/sort-definition';
import { ObjectUtil } from '@contrail/util';
import { FilterObjects, FilterDefinition, FilterPropertyCriteria, FilterPropertyDefinition } from '@contrail/filters';
import {
  DocumentGenerationConfig,
  DocumentGenerationResults,
  GenerationOptions,
  GridTemplateDimensionsDefinition,
} from './document-generator.interfaces';
import { ShowcaseFrame } from '@contrail/document-generation/lib/frames/frame';
import { FormulaProcessor, PropertyType, TypeProperty } from '@contrail/types';
import { SortObjects } from '@components/sort/sort-objects';
import { StyleDefinition } from '@contrail/documents';
import { AssortmentsService } from '@common/assortments/assortments.service';
import { AssortmentUtil } from '@common/assortments/assortment-util';
import { RootStoreState } from '@rootstore';
import { Store } from '@ngrx/store';
import { FrameTemplatesSelectors } from '@common/frame-templates/frame-templates-store';
import { FrameTemplatesService } from '@common/frame-templates/frame-templates.service';
import { tap } from 'rxjs';

export class ShowcaseGenerateFramesUtil {
  public static PROJ_NAME_PROP = {
    sort: 'asc',
    typeRootSlug: 'project-item',
    values: null,
    propertyDefinition: {
      id: 'project.name',
      slug: 'project.name',
      label: 'Project',
      propertyType: PropertyType.String,
    },
  };
  public static gridTemplateOptions: Array<GridTemplateDimensionsDefinition> = [
    {
      label: '4 x 1 (X-Large)',
      gridDimensions: { rows: 1, cols: 4 },
      componentPadding: 10,
      layoutPreviewImage: 'frame-layout-xlarge',
    },
    {
      label: '4 x 2 (Large)',
      gridDimensions: { rows: 2, cols: 4 },
      componentPadding: 10,
      layoutPreviewImage: 'frame-layout-large',
    },
    {
      label: '6 x 2 (Medium)',
      gridDimensions: { rows: 2, cols: 6 },
      componentPadding: 10,
      layoutPreviewImage: 'frame-layout-medium',
    },
    {
      label: '8 x 3 (Small)',
      gridDimensions: { rows: 3, cols: 8 },
      componentPadding: 10,
      layoutPreviewImage: 'frame-layout-small',
    },
  ];

  public static DEFAULT_COMPONENT_PROPERTY_STYLE: StyleDefinition = {
    font: {
      size: 10,
    },
    text: {
      align: 'center',
    },
    color: 'black',
  };

  public static DEFAULT_PANEL_PROPERTY_STYLE: StyleDefinition = {
    font: {
      size: 12,
      weight: 'bold',
    },
    color: 'white',
  };

  public static ITEM_TYPE_PROPERTY = {
    label: 'Item Type',
    slug: 'type',
    propertyType: PropertyType.TypeReference,
    referencedTypePath: 'item',
    referencedTypeRootSlug: 'item',
  };

  private static getItemCount(group) {
    let count = group.data.length;
    if (group.subGroups?.length > 0) {
      group.subGroups.forEach((subGroup) => {
        count = count + this.getItemCount(subGroup);
      });
    }
    return count;
  }

  private static getFrameCount(group, currentDepth, maxDepth) {
    let count = currentDepth < maxDepth ? 0 : group.subGroups.length;
    currentDepth++;
    if (group.subGroups?.length > 0) {
      group.subGroups.forEach((subGroup) => {
        count = count + this.getFrameCount(subGroup, currentDepth, maxDepth);
      });
    }
    return count;
  }

  /** Takes a set of options from the modal the user fills out for
   * generation options, then:
   * 1) Executes the data query (Get assortment items)
   * 2) Fitlers / Sorts data as needed
   * 3) Uses the library @contrail/data-grouping to create sets of data based on grouping
   * 4) Generates the correct showcase frames for the given data.
   *
   * getCountsOnly - get item and frame count based on created data group structure and
   * does not generate document elements
   */
  public static async generateShowcaseFrames(
    options: GenerationOptions,
    config?: { getCountsOnly?: boolean },
  ): Promise<DocumentGenerationResults> {
    console.log('generateDocument: ', options);
    let assortmentItems = options.assortmentItems;

    const data = this.fromAssortmentModelToItemModel(assortmentItems);
    const filteredData = await this.filterAndSortData(data, options.filterDefinition, options.sortProperties);

    //** Item component template */
    const componentPropertyTemplate: ComponentPropertyTemplate = {
      imageDimension: { width: 600 },
      imageLocation: 'top',
      textHorizontalAlignment: 'center',
      properties: [],
    };
    if (options.itemComponentProperties) {
      componentPropertyTemplate.properties = options.itemComponentProperties as Array<DocumentPropertyDefinition>;
    }

    // Defaults if none specified in options. ///////
    const componentGridTemplate: ComponentGridTemplate = {
      gridDimensions: { cols: 7, rows: 2 },
      componentPadding: 10,
      componentTemplate: {
        propertyTemplate: componentPropertyTemplate,
      },
      alignment: 'left',
    };
    const documentTemplateDefinition = this.getDocumentTemplate();
    documentTemplateDefinition.componentGridTemplate = componentGridTemplate;

    if (options.gridLayoutOrientation === 'HORIZONTAL') {
      documentTemplateDefinition.frameOrientation = Orientation.HORIZONTAL;
    } else {
      documentTemplateDefinition.frameOrientation = Orientation.VERTICAL;
    }

    if (options.gridLayoutDimensions) {
      documentTemplateDefinition.componentGridTemplate.componentPadding =
        options.gridLayoutDimensions.componentPadding || 10;
      documentTemplateDefinition.componentGridTemplate.gridDimensions = ObjectUtil.cloneDeep(
        options.gridLayoutDimensions.gridDimensions,
      ) || { cols: 6, rows: 2 };
    }

    if (options.alignment) {
      documentTemplateDefinition.componentGridTemplate.alignment = options.alignment;
    }

    const leafNodeDataCount =
      documentTemplateDefinition.componentGridTemplate.gridDimensions.cols *
      documentTemplateDefinition.componentGridTemplate.gridDimensions.rows;
    const dataGroupStructure = DataGroupGenerator.buildDataGroupStructure(
      filteredData,
      options.groupingProperties,
      leafNodeDataCount,
    );

    if (config?.getCountsOnly) {
      return {
        filteredData,
        itemCount: this.getItemCount(dataGroupStructure.rootGroup),
        frameCount: this.getFrameCount(dataGroupStructure.rootGroup, 0, dataGroupStructure.groupingProperties.length),
      };
    }

    const params: DocumentGenerationOptions = {
      startingCoordinates: { x: 0, y: 0 },
      data: {
        dataGroup: dataGroupStructure,
      },
      documentTemplateDefinition,
    };

    // Panel Template
    const panelPropertyTemplate = options.panelPropertyTemplate;
    if (panelPropertyTemplate) {
      // Exclude header if side panel is added
      delete params.documentTemplateDefinition.frameHeaderTemplate;

      params.documentTemplateDefinition.frameInfoPanelTemplate = {
        panelDocumentTemplate: {
          style: {
            color: '#ffffff',
            font: {
              size: 20,
            },
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
            border: {
              radius: 4,
            },
          },
          size: {
            width: 250,
            height: 400,
          },
        },
        propertyTemplate: panelPropertyTemplate,
      };
    }

    if (options.frameTemplate) {
      params.frameTemplate = options.frameTemplate;
    }

    const frames: Array<ShowcaseFrame> = ShowcaseFrameGenerator.generateFrames(params);

    const results: DocumentGenerationResults = {
      frames,
      filteredData,
    };
    return results;
  }

  /** Gets the top level document template to use for generation
   */
  public static getDocumentTemplate(): DocumentTemplate {
    return {
      frameOrientation: Orientation.VERTICAL,
      frameSize: { width: 1200, height: 675 },
      framePadding: 30,
      frameHeaderTemplate: {
        style: {
          color: '#000000',
          font: {
            size: 20,
          },
          backgroundColor: '#FFFFFF',
        },
        size: {
          height: 60,
        },
      },
      frameGroupHeaderTemplate: {
        style: {
          color: '#ffffff',
          font: {
            size: 20,
          },
          backgroundColor: 'black',
        },
        size: {
          height: 60,
        },
      },
      componentGridTemplate: null,
    };
  }

  /**
   * Gets the property component template to use when generating components
   * @returns
   */
  public static async getPropertyComponentTemplate() {
    const propertyTemplate: ComponentPropertyTemplate = {
      imageDimension: { width: 600 },
      imageLocation: 'top',
      textHorizontalAlignment: 'center',
      properties: [],
    };
    return propertyTemplate;
  }

  public static fromAssortmentModelToItemModel(data: Array<any>) {
    const results = [];
    data.forEach((ai) => {
      let itemModel = {
        item: ai.item,
        projectItem: ai.projectItem,
        viewable: ai.item,
        assortment: { id: ai.assortmentId },
      };
      if (ai.entityType === 'assortment-item') {
        itemModel = Object.assign(itemModel, { assortmentItem: ai });
      }
      results.push(itemModel);
    });
    return results;
  }

  /** Gets assortment items to add to the board. */
  public static async getAssortment(assortmentId: string, includeFamilyItems = true) {
    let assortment = await new Entities().get({
      entityName: 'assortment',
      id: assortmentId,
      relations: ['assortmentItems', 'assortmentItems.item', 'assortmentItems.projectItem'],
    });

    let optionItemData = assortment.assortmentItems;

    let familyItemData = [];
    if (assortment.itemsDownloadURL) {
      const response = await fetch(assortment.itemsDownloadURL);
      optionItemData = await response.json();
      assortment.assortmentItems = optionItemData;
    }
    let projectIds = [];
    optionItemData.forEach((item) => {
      if (item.projectItem) {
        projectIds.push(item.projectItem.projectId);
      }
    });
    let projectMap = {};
    if (projectIds.length > 0) {
      projectIds = [...new Set(projectIds)];
      const projects = await new Entities().get({
        entityName: 'project',
        criteria: { ids: projectIds },
      });
      projectMap = projects.reduce((map, obj) => {
        map[obj.id] = obj;
        return map;
      }, {});
      optionItemData.forEach((item) => {
        if (item.projectItem) {
          item.projectItem.project = projectMap[item.projectItem.projectId];
        }
      });
    }

    if (includeFamilyItems) {
      optionItemData = optionItemData.filter((itemData) => itemData.item?.roles.includes('option'));
      assortment = await AssortmentsService.getAssortmentFamilyItems(assortment);
      familyItemData = AssortmentUtil.convertItemsToFamilyItemData(assortment.familyLevelItems);
      if (projectIds.length > 0) {
        familyItemData.forEach((item) => {
          if (item.projectItem) {
            item.projectItem.project = projectMap[item.projectItem.projectId];
          }
        });
      }
    }

    assortment.familyItemData = familyItemData;
    assortment.optionItemData = optionItemData;
    return assortment;
  }

  /** Fitlers and sorts assortment item data */
  private static async filterAndSortData(
    data: Array<any>,
    filterDefinition: FilterDefinition,
    sortProperties: Array<SortDefinition>,
  ) {
    let processed = data;

    const itemType = await new Types().getType({ path: 'item' });
    const itemTypeProperties = [...itemType.typeProperties, ShowcaseGenerateFramesUtil.ITEM_TYPE_PROPERTY];

    const projectItemType = await new Types().getType({ path: 'project-item' });
    const assortmentItemType = await new Types().getType({ path: 'assortment-item' });
    const typeProperties = itemTypeProperties
      .concat(projectItemType.typeProperties)
      .concat(assortmentItemType.typeProperties);

    // Derive values by formula if applicable
    FormulaProcessor.processFormulasForEntities(data, typeProperties);

    // Filter data
    const filters: FilterDefinition = ObjectUtil.cloneDeep(filterDefinition);
    // Adjust filters to indexCorrectly
    console.log('Filters:  ', filters);
    processed = FilterObjects.filter(data, filters.filterCriteria);

    // Sort data
    if (!sortProperties) {
      const sortDefinition: SortDefinition = {
        direction: SortDirection.ASCENDING,
        propertyLabel: 'Name',
        propertySlug: 'name',
        propertyType: PropertyType.String,
      };
      sortProperties = [sortDefinition];
    }
    const clonedSortProperties = ObjectUtil.cloneDeep(sortProperties);
    for (let prop of clonedSortProperties) {
      let itemProp = itemTypeProperties.find((p) => p.slug === prop.propertySlug);
      let projectItemProp = projectItemType.typeProperties.find((p) => p.slug === prop.propertySlug);
      let assortmentItemProp = assortmentItemType.typeProperties.find((p) => p.slug === prop.propertySlug);
      if (itemProp) {
        prop.propertySlug = 'item.' + prop.propertySlug;
      } else if (projectItemProp) {
        prop.propertySlug = 'projectItem.' + prop.propertySlug;
      } else if (assortmentItemProp) {
        prop.propertySlug = 'assortmentItem.' + prop.propertySlug;
      }
    }
    processed = SortObjects.sort(processed, clonedSortProperties);
    return processed;
  }

  public static async generateProperties() {
    let properties = [];
    const itemType = await new Types().getType({ path: 'item' });
    const projectItemType = await new Types().getType({ path: 'project-item' });
    const assortmentItemType = await new Types().getType({ path: 'assortment-item' });

    properties.push(
      ...itemType.typeProperties.map((p) => {
        return {
          propertyDefinition: p,
          typeRootSlug: 'item',
          sort: SortOrderOptions.ASC,
          values: null,
        };
      }),
    );
    properties.push(
      ...projectItemType.typeProperties
        .filter((p) => !['createdOn', 'updatedOn', 'name'].includes(p.slug))
        .map((p) => {
          return {
            propertyDefinition: p,
            typeRootSlug: 'project-item',
            sort: SortOrderOptions.ASC,
            values: null,
          };
        }),
    );
    properties.push(
      ...assortmentItemType.typeProperties
        .filter((p) => !['createdOn', 'updatedOn', 'name', 'itemFamily', 'itemOption'].includes(p.slug))
        .map((p) => {
          return {
            propertyDefinition: p,
            typeRootSlug: 'assortment-item',
            sort: SortOrderOptions.ASC,
            values: null,
          };
        }),
    );
    properties.push({
      typeRootSlug: ShowcaseGenerateFramesUtil.PROJ_NAME_PROP.typeRootSlug,
      propertyDefinition: ShowcaseGenerateFramesUtil.PROJ_NAME_PROP.propertyDefinition,
      slug: ShowcaseGenerateFramesUtil.PROJ_NAME_PROP.propertyDefinition.slug,
      includeLabel: false,
    });
    properties = properties.sort((p1, p2) => (p1.propertyDefinition.label > p2.propertyDefinition.label ? 1 : -1));
    return properties;
  }

  public static async initFilterAndSortDefinition() {
    let filterProperties: Array<TypeProperty> = [];
    const itemType = await new Types().getType({ path: 'item' });
    const itemTypeProperties = [...itemType.typeProperties, ShowcaseGenerateFramesUtil.ITEM_TYPE_PROPERTY];

    const projectItemType = await new Types().getType({ path: 'project-item' });
    const assortmentItemType = await new Types().getType({ path: 'assortment-item' });
    filterProperties = [
      ...itemTypeProperties,
      ...projectItemType.typeProperties.filter((p) => !['createdOn', 'updatedOn'].includes(p.slug)),
      ...assortmentItemType.typeProperties.filter(
        (p) => !['createdOn', 'updatedOn', 'itemFamily', 'itemOption'].includes(p.slug),
      ),
    ];
    filterProperties.push(ShowcaseGenerateFramesUtil.PROJ_NAME_PROP.propertyDefinition);

    const filterDefinitions = ObjectUtil.cloneDeep(filterProperties.sort((p1, p2) => (p1.label < p2.label ? -1 : 1)));
    const propertyMap: any = {};
    for (let propDef of filterDefinitions) {
      let itemProp = itemTypeProperties.find((p) => p.slug === propDef.slug);
      let projectItemProp = projectItemType.typeProperties.find((p) => p.slug === propDef.slug);
      let assortmentItemProp = assortmentItemType.typeProperties.find((p) => p.slug === propDef.slug);
      const propSlug = propDef.slug;
      if (itemProp) {
        propDef.slug = 'item.' + propDef.slug;
        propertyMap[propSlug] = propDef;
      } else if (projectItemProp) {
        propDef.slug = 'projectItem.' + propDef.slug;
        propertyMap[propSlug] = propDef;
      } else if (assortmentItemProp) {
        propDef.slug = 'assortmentItem.' + propDef.slug;
        propertyMap[propSlug] = propDef;
      }
    }
    const projectNameDef = { ...ShowcaseGenerateFramesUtil.PROJ_NAME_PROP.propertyDefinition };
    projectNameDef.slug = 'projectItem.' + projectNameDef.slug;
    propertyMap[ShowcaseGenerateFramesUtil.PROJ_NAME_PROP.propertyDefinition.slug] = projectNameDef;
    const filterDefinition: FilterDefinition = {
      filterPropertyDefinitions: filterDefinitions as Array<FilterPropertyDefinition>,
      filterCriteria: {
        propertyCriteria: [],
      },
    };
    const sortProperties: Array<SortDefinition> = filterProperties.map((property) => {
      return {
        propertySlug: property.slug,
        propertyLabel: property.label,
        propertyType: property.propertyType,
        direction: SortDirection.ASCENDING,
      };
    });
    return {
      propertyMap,
      filterDefinition,
      sortProperties,
    };
  }

  public static async getDefaultPropertyComponentDefinitions() {
    const itemType = await new Types().getType({ path: 'item' });
    const DEFAULT_ITEM_CARD_PROPERTIES = [
      {
        includeLabel: false,
        style: {
          font: {
            size: 15,
          },
        },
        size: {
          width: 165,
          height: 15,
        },
        enabled: true,
        slug: 'annotation',
        typeRootSlug: 'item',
        propertyDefinition: null,
      },
      {
        enabled: true,
        includeLabel: false,
        propertyDefinition: null,
        typeRootSlug: 'item',
        slug: 'thumbnail',
        size: {
          width: 125,
          height: 125,
        },
      },
      {
        propertyDefinition: itemType.typeProperties.find((p) => p.slug === 'name'),
        typeRootSlug: 'item',
        includeLabel: false,
        enabled: 'true',
        slug: 'name',
        style: {
          color: 'black',
          font: {
            size: 10,
          },
          text: {
            align: 'center',
          },
        },
      },
    ];
    return DEFAULT_ITEM_CARD_PROPERTIES;
  }

  public static getGenerationConfig(
    assortmentId: string,
    groupingProperties: Array<any>,
    filterDefinition: FilterDefinition,
    sortDefinitions: Array<SortDefinition>,
    componentProperties: Array<any>,
    gridLayoutOrientation: string,
    gridTemplateDimensions: any,
    gridAlignment: 'left' | 'right' | 'center',
    groupMultiSelectInSeparateFrame: boolean,
    includeFramePanel: boolean,
    selectedPanelPropertyTemplate: any,
    frameTemplate: any,
    itemLevel,
  ): DocumentGenerationConfig {
    const filterDefinitions: Array<FilterPropertyCriteria> = filterDefinition.filterCriteria.propertyCriteria.map(
      (prop) => {
        const filterPropertyDefinition: FilterPropertyDefinition = {
          slug: prop.filterPropertyDefinition.slug.substring(prop.filterPropertyDefinition.slug.indexOf('.') + 1), //item.name
          propertyType: prop.filterPropertyDefinition.propertyType,
        };
        const filterPropertyCriteria: FilterPropertyCriteria = {
          criteriaValue: prop.criteriaValue,
          filterConditionType: prop.filterConditionType,
          filterPropertyDefinition,
        };
        return filterPropertyCriteria;
      },
    );
    const itemComponentProperties = componentProperties.map((prop) => {
      return {
        includeLabel: prop.includeLabel,
        isHidden: prop.isHidden,
        slug: prop.slug || '',
        style: prop.style || {},
        typeRootSlug: prop.typeRootSlug,
      };
    });
    const sortProperties: Array<SortDefinition> = sortDefinitions.map((prop) => {
      const sortDefinition: SortDefinition = {
        propertySlug: prop.propertySlug,
        direction: prop.direction,
        propertyType: prop.propertyType,
      };
      return sortDefinition;
    });
    const generationConfig: DocumentGenerationConfig = {
      assortmentId: assortmentId,
      groupingProperties,
      gridLayoutOrientation: gridLayoutOrientation,
      filterDefinitions,
      sortProperties,
      itemLevel,
      itemComponentProperties,
      gridLayoutDimensions: gridTemplateDimensions,
      alignment: gridAlignment,
      groupMultiSelectInSeparateFrame: groupMultiSelectInSeparateFrame,
    };
    if (includeFramePanel) {
      generationConfig.includeFramePanel = includeFramePanel;
      const panelPropertyTemplate: any = {
        properties: selectedPanelPropertyTemplate.properties.map((prop) => {
          return {
            includeLabel: prop.includeLabel,
            isHidden: prop.isHidden,
            slug: prop.slug || '',
            style: prop.style || {},
            typeRootSlug: prop.typeRootSlug,
          };
        }),
      };
      generationConfig.panelPropertyTemplate = panelPropertyTemplate;
    }
    if (frameTemplate && frameTemplate.id !== 'default') {
      generationConfig.frameTemplateId = frameTemplate.id;
    } else {
      generationConfig.frameTemplateId = null;
    }
    return generationConfig;
  }

  public static async generationConfigToOptions(
    documentGenerationConfig: DocumentGenerationConfig,
    store: Store<RootStoreState.State>,
    frameTemplatesService: FrameTemplatesService,
  ): Promise<DocumentGenerationOptions> {
    const definitions = await ShowcaseGenerateFramesUtil.initFilterAndSortDefinition();
    const propertyMap = definitions.propertyMap;
    const availableProperties = await ShowcaseGenerateFramesUtil.generateProperties();
    const availableViewProperties = availableProperties.map((p) => {
      return {
        typeRootSlug: p.typeRootSlug,
        propertyDefinition: p.propertyDefinition,
        slug: p.propertyDefinition.slug,
        includeLabel: false,
      };
    });
    let assortment = null;
    let panelPropertyTemplate = null;
    let gridTemplateDimensions = null;
    let selectedFrameTemplate = null;
    let gridLayoutOrientation = null;
    let selectedComponentProperties = null;
    let includeFramePanel = false;
    let groupMultiSelectInSeparateFrame = false;
    let itemLevel;
    const selectedProperties = new Map();
    let sortDefinitions;
    const groupings = [];
    let filterDefinition = {
      filterCriteria: {
        propertyCriteria: [],
      },
    };
    assortment = await ShowcaseGenerateFramesUtil.getAssortment(
      documentGenerationConfig.assortmentId,
      documentGenerationConfig.itemLevel === 'family',
    );
    const selectedPropertySlugs = documentGenerationConfig.groupingProperties.map((prop) => prop.slug);
    const properties = availableProperties.filter((prop) =>
      selectedPropertySlugs.includes(prop.propertyDefinition.slug),
    );
    for (let i = 0; i < properties.length; i++) {
      selectedProperties.set(i, properties[i]);
      groupings.push(i);
    }
    if (documentGenerationConfig.filterDefinitions) {
      filterDefinition.filterCriteria.propertyCriteria = documentGenerationConfig.filterDefinitions.map((filter) => {
        return {
          filterConditionType: filter.filterConditionType,
          criteriaValue: filter.criteriaValue,
          filterPropertyDefinition: propertyMap[filter.filterPropertyDefinition.slug],
        };
      });
    }
    if (documentGenerationConfig.sortProperties) {
      sortDefinitions = documentGenerationConfig.sortProperties.map((property) => {
        const prop = propertyMap[property.propertySlug];
        return { ...property, propertyLabel: prop.label };
      });
    }
    includeFramePanel = documentGenerationConfig.includeFramePanel || false;
    groupMultiSelectInSeparateFrame = documentGenerationConfig.groupMultiSelectInSeparateFrame || false;
    if (documentGenerationConfig.frameTemplateId) {
      selectedFrameTemplate = { id: documentGenerationConfig.frameTemplateId };
    } else {
      selectedFrameTemplate = { id: 'default' };
    }
    gridTemplateDimensions = this.gridTemplateOptions.find(
      (o) =>
        o.gridDimensions.rows === documentGenerationConfig.gridLayoutDimensions.gridDimensions.rows &&
        o.gridDimensions.cols === documentGenerationConfig.gridLayoutDimensions.gridDimensions.cols,
    );
    gridLayoutOrientation = documentGenerationConfig.gridLayoutOrientation;

    selectedComponentProperties = documentGenerationConfig.itemComponentProperties.map((p) => {
      const propertyDefinition = availableViewProperties.find(
        (property) => property.slug === p.slug,
      )?.propertyDefinition;
      if (propertyDefinition) {
        return { ...p, propertyDefinition };
      }
      return { ...p };
    });
    if (documentGenerationConfig.panelPropertyTemplate) {
      panelPropertyTemplate = {
        properties: documentGenerationConfig.panelPropertyTemplate.properties.map((p) => {
          const propertyDefinition = availableViewProperties.find(
            (property) => property.slug === p.slug,
          )?.propertyDefinition;
          if (propertyDefinition) {
            return { ...p, propertyDefinition };
          }
          return { ...p };
        }),
      };
    }
    itemLevel = documentGenerationConfig.itemLevel || 'option';
    const generationOptions: any = {
      assortmentItems: itemLevel === 'option' ? assortment.optionItemData : assortment.familyItemData,
      groupingProperties: Array.from(selectedProperties.values()),
      gridLayoutOrientation: gridLayoutOrientation,
      filterDefinition: filterDefinition,
      sortProperties: sortDefinitions,
      itemComponentProperties: selectedComponentProperties,
      gridLayoutDimensions: gridTemplateDimensions,
      groupMultiSelectInSeparateFrame: groupMultiSelectInSeparateFrame,
    };
    if (includeFramePanel) {
      generationOptions.panelPropertyTemplate = panelPropertyTemplate;
    }

    if (selectedFrameTemplate && selectedFrameTemplate.id !== 'default') {
      store
        .select(FrameTemplatesSelectors.getFrameTemplateById(selectedFrameTemplate.id))
        .pipe(
          tap((frameTemplate: any) => {
            generationOptions.frameTemplate = ObjectUtil.cloneDeep(frameTemplate);
          }),
        )
        .subscribe();
      if (!generationOptions.frameTemplate.document.elements) {
        generationOptions.frameTemplate = await frameTemplatesService.getFrameTemplateById(selectedFrameTemplate.id);
      }
    }
    return generationOptions;
  }
}
